@charset "UTF-8";
@font-face {
  font-family: 'MyYuGothic';
  src: local("游ゴシック Medium"), local("YuGothic-Medium"), local("Yu Gothic Medium"), local("游ゴシック体"), local("YuGothic"), local("YuGothic-Regular");
}

@font-face {
  font-family: 'MyYuGothic';
  font-weight: bold;
  src: local("游ゴシック Bold"), local("YuGothic-Bold"), local("Yu Gothic"), local("游ゴシック体"), local("YuGothic");
}

@font-face {
  font-family: Lato;
  src: url("../fonts/Lato/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Lato;
  font-weight: bold;
  src: url("../fonts/Lato/Lato-Bold.ttf") format("truetype");
}

/**
 * Base Style
 */
*,
*:after,
*:before {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

html.is-gnav-show {
  overflow-y: hidden;
}

body {
  font-family: "ヒラギノ角ゴ ProN","Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", "MS PGothic", "メイリオ", Meiryo, ssans-serif;
  margin: 0;
  min-width: 1280px;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #393230;
}

@media screen and (max-width: 767px) {
  body {
    min-width: inherit;
  }
}

a {
  color: #393230;
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  a:hover {
    text-decoration: none;
    opacity: 0.7;
  }
}

a:active {
  text-decoration: none;
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

.text-feature-spacing {
  font-feature-settings: "palt" 1;
}

/**
 * Coming soon Style
*/
@media screen and (max-width: 767px) {
  body.cat-comingsoon {
    min-width: 1280px;
  }
}

.comingsoon-content {
  background: url(../img/comingsoon/main_bg.jpg) no-repeat center top;
  background-size: cover;
  padding: 154px 0 80px;
}

.comingsoon-inner {
  margin: 0 auto;
  padding: 0 150px;
  width: 1280px;
}

.comingsoon-title {
  margin: 0;
  text-align: center;
}

.comingsoon-title > .text1 {
  font-family: "游ゴシック", "游ゴシック体", "Yu Gothic", YuGothic, sans-serif, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", "メイリオ", Meiryo;
  display: block;
  padding-bottom: 30px;
  padding-left: 36px;
  font-size: 5.2rem;
  font-weight: normal;
  letter-spacing: 0.7em;
  line-height: 1;
}

.comingsoon-title > .text2 {
  font-family: 'Marcellus', "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  display: block;
  border-top: 1px solid #c42d05;
  padding: 25px 0 0 0;
  line-height: 1;
  font-size: 0;
}

.category-text1 {
  margin: 60px 0 0 0;
  font-size: 2.8rem;
  line-height: 1;
  text-align: center;
  letter-spacing: 0.05em;
}

.category-text2 {
  margin: 17.5px 0 -7.5px 0;
  font-size: 2rem;
  line-height: 1.75;
  text-align: center;
  letter-spacing: 0.05em;
}

.news {
  background-color: rgba(239, 196, 95, 0.3);
  margin: 80px 0 0;
  padding: 60px 80px 80px;
}

.news-title {
  margin: 0;
  font-size: 2.4rem;
  line-height: 1;
  color: #c42d05;
}

.news-text {
  margin: 33px 0 -7px;
  font-size: 1.8rem;
  line-height: 1.77778;
}

.contact-title {
  margin: 55px 0 0;
  font-size: 2.4rem;
  line-height: 1;
}

.contact-text {
  margin: 35px 0 0 0;
  font-size: 2.8rem;
  line-height: 1;
}

.contact-text > .text1 {
  font-weight: bold;
  margin-left: 40px;
}

.contact-text > .text1:first-child {
  margin-left: 0;
}

.contact-text > .text1 > strong {
  font-size: 3.8rem;
}

.footer-address {
  margin: 0 auto;
  padding: 40px 40px 60px;
  width: 1280px;
}

.footer-address-title {
  margin: 0;
  line-height: 1;
  font-size: 2.8rem;
  font-weight: normal;
}

.footer-address-title > .text1 {
  margin-right: 15px;
}

.footer-address-text {
  margin: 15px 0 -5px 0;
  font-size: 1.6rem;
  line-height: 1.71429;
  letter-spacing: 0.05em;
}

.copyright {
  display: block;
  background-color: #3c4044;
  padding: 13px 0 12px;
  text-align: center;
  color: #fff;
  font-size: 1.3rem;
  line-height: 1;
}

.button-map {
  display: inline-block;
  position: relative;
  background: url(../img/comingsoon/icon_blank.png) no-repeat right 18px center;
  margin-top: 20px;
  border: 1px solid #c42d05;
  padding: 13px 50px 13px 20px;
  font-size: 1.8rem;
  line-height: 1;
  font-weight: bold;
  color: #c42d05;
}
