/**
 * Coming soon Style
*/
body.cat-comingsoon {
  @include media-query-sp {
    min-width: 1280px;
  }
}
.comingsoon-content {
  background: url(../img/comingsoon/main_bg.jpg) no-repeat center top;
  background-size: cover;
  padding: 154px 0 80px;
}
.comingsoon-inner {
  margin: 0 auto;
  padding: 0 150px;
  width: 1280px;
}
.comingsoon-title {
  margin: 0;
  text-align: center;
  > .text1 {
    @include font-yugo;
    display: block;
    padding-bottom: 30px;
    padding-left: 36px;
    font-size: 5.2rem;
    font-weight: normal;
    letter-spacing: 0.7em;
    line-height: 1;
  }
  > .text2 {
    @include font-eng;
    display: block;
    border-top: 1px solid #c42d05;
    padding: 25px 0 0 0;
    line-height: 1;
    font-size: 0;
  }
}
.category-text1 {
  margin: 60px 0 0 0;
  font-size: 2.8rem;
  line-height: 1;
  text-align: center;
  letter-spacing: 0.05em;
}
.category-text2 {
  margin: (25-20*(35/20-1)/2)+px 0 (-20*(35/20-1)/2)+px 0;
  font-size: 2rem;
  line-height: (35/20);
  text-align: center;
  letter-spacing: 0.05em;
}
.news {
  background-color: rgba(#efc45f,0.3);
  margin: 80px 0 0;
  padding: 60px 80px 80px;
  &-title {
    margin: 0;
    font-size: 2.4rem;
    line-height: 1;
    color: #c42d05;
  }
  &-text {
    margin: (40-18*(32/18-1)/2)+px 0 (-18*(32/18-1)/2)+px;
    font-size: 1.8rem;
    line-height: (32/18);
  }
}
.contact {
  &-title {
    margin: 55px 0 0;
    font-size: 2.4rem;
    line-height: 1;
  }
  &-text {
    margin: 35px 0 0 0;
    font-size: 2.8rem;
    line-height: 1;
    > .text1 {
      font-weight: bold;
      margin-left: 40px;
      &:first-child {
        margin-left: 0;
      }
      > strong {
        font-size: 3.8rem;
      }
    }
  }
}
.footer-address {
  margin: 0 auto;
  padding: 40px 40px 60px;
  width: 1280px;
  &-title {
    margin: 0;
    line-height: 1;
    font-size: 2.8rem;
    font-weight: normal;
    > .text1 {
      margin-right: 15px;
    }
  }
  &-text {
    margin: (20-14*(24/14-1)/2)+px 0 (-14*(24/14-1)/2)+px 0;
    font-size: 1.6rem;
    line-height: (24/14);
    letter-spacing: 0.05em;
  }
}
.copyright {
  display: block;
  background-color: #3c4044;
  padding: 13px 0 12px;
  text-align: center;
  color: #fff;
  font-size: 1.3rem;
  line-height: 1;
}
.button-map {
  display: inline-block;
  position: relative;
  background: url(../img/comingsoon/icon_blank.png) no-repeat right 18px center;
  margin-top: 20px;
  border: 1px solid #c42d05;
  padding: 13px 50px 13px 20px;
  font-size: 1.8rem;
  line-height: 1;
  font-weight: bold;
  color: #c42d05;
}
