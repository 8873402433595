@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
@mixin media-query-pcfirst($breakpoint) {
  @if map-has-key($breakpoints-pcfirst, $breakpoint) {
    @media #{unquote(map-get($breakpoints-pcfirst, $breakpoint))} {
      @content;
    }
  }
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    + "Available breakpoints are: #{map-keys($breakpoints-pcfirst)}.";
  }
}
@mixin media-query-pc {
  @include media-query-spfirst(sm) {
    @content;
  }
}
@mixin media-query-sp {
  @include media-query-pcfirst(sm) {
    @content;
  }
}
//font
@font-face {
  font-family: 'MyYuGothic';
  src: local("游ゴシック Medium"), local("YuGothic-Medium"), local("Yu Gothic Medium"), local("游ゴシック体"), local("YuGothic"), local("YuGothic-Regular");
}

@font-face {
  font-family: 'MyYuGothic';
  font-weight: bold;
  src: local("游ゴシック Bold"), local("YuGothic-Bold"), local("Yu Gothic"), local("游ゴシック体"), local("YuGothic");
}
@font-face {
   font-family: Lato;
   src: url('../fonts/Lato/Lato-Regular.ttf') format("truetype");
}
@font-face {
   font-family: Lato;
   font-weight: bold;
   src: url('../fonts/Lato/Lato-Bold.ttf') format("truetype");
}
@mixin font-go {
  font-family: "ヒラギノ角ゴ ProN","Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", "MS PGothic", "メイリオ", Meiryo, ssans-serif;
}
@mixin font-yugo {
  font-family: "游ゴシック", "游ゴシック体", "Yu Gothic", YuGothic, sans-serif, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", "メイリオ", Meiryo;
}
@mixin font-eng {
  font-family: 'Marcellus', "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
@mixin font-mincho {
  font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "游明朝", YuMincho, serif;
}
@mixin font-yumincho {
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
@mixin font-meirio {
  font-family: "メイリオ", Meiryo, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}
@mixin media-query-spfirst($breakpoint) {
  @if map-has-key($breakpoints-spfirst, $breakpoint) {
    @media #{unquote(map-get($breakpoints-spfirst, $breakpoint))} {
      @content;
    }
  }
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    + "Available breakpoints are: #{map-keys($breakpoints-spfirst)}.";
  }
}
//border
@mixin dotted($direction:left,$color:rgba(#000, 1.0), $stripe: 1, $spacing: 4, $height:1){
  background-size: ($stripe+$spacing)*1px ($stripe+$spacing)*1px;
  @if $direction == bottom {
    background-image: linear-gradient(to bottom, $color, $color ($stripe/($stripe+$spacing))*100%, transparent ($stripe/($stripe+$spacing))*100%, transparent 100%);
    height: 100%;
    width: $height*1px;

  } @else {
    background-image: linear-gradient(to right, $color, $color ($stripe/($stripe+$spacing))*100%, transparent ($stripe/($stripe+$spacing))*100%, transparent 100%);
    height: $height*1px;
    width: 100%;
  }
  border: none;
}
