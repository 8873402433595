/**
 * Base Style
 */
*,
*:after,
*:before {
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
  &.is-gnav-show {
    overflow-y: hidden;
  }
}
body {
  @include font-go;
  margin: 0;
  min-width: 1280px;
  font-size: 1.6rem;
  line-height: (24/16);
  color: $text-base-color;
  @include media-query-sp {
    min-width: inherit;
  }
}
a {
  color: $link-base-color;
  text-decoration: none;
  &:hover{
    @include media-query-pc {
      text-decoration: none;
      opacity: 0.7;
    }
  }
  &:active {
    text-decoration: none;
  }
}
img {
  max-width: 100%;
  vertical-align: bottom;
}
.text-feature-spacing {
  font-feature-settings : "palt" 1;//pkna
}
