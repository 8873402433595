//breakpoints
$breakpoints-spfirst: (
  'xs': 'screen and (min-width: 480px)',
  'sm': 'screen and (min-width: 768px)',
  'md': 'screen and (min-width: 992px)',
  'lg': 'screen and (min-width: 1024px)',
) !default;
$breakpoints-pcfirst: (
  'xs': 'screen and (max-width: 479px)',
  'sm': 'screen and (max-width: 767px)',
  'md': 'screen and (max-width: 991px)',
  'lg': 'screen and (max-width: 1023px)',
) !default;

// color
$key-color: #f6de50;
$key-color-dark: #d0b000;
$key-color-light: #3ca848;
$text-base-color: #393230;
$link-base-color: #393230;
$link-hover-color: #393230;
$color-yellow: #dbbf01;
$color-red: #e9295f;
$color-gray-light: #dcdcdc;
$color-blue-dark: #1f3143;
$color-blue-light: #4c5a69;

// カテゴリーの配列名
$namelist: water-set, tanpin, total, regularly, other;
$namelist-delay: 1.5, 1.6, 1.7, 1.8, 1.9;
